html, body {
	width: 100%;
	font-size: 26px;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
/*  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
}
#root {
	width: 100%;
}
@media screen and (max-width: 640px) {
	html {
		font-size: 22px;
	}
}

@media screen and (max-width: 460px) {
	html {
		font-size: 20px;
	}
}
@media screen and (max-width: 360px) {
	html {
		font-size: 16px;
	}
}