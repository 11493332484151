/* 容器 */
.index-wrapper{
	width: 100%;
}

/* 导航 */
.header-wrapper {
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 999;
	width: 100%;
	height: 4rem;
	padding: 0 10%;
}
.header-logo-wrapper {
	display: block;
	width: 4rem;
	height: 1.2rem;
}
.header-logo {
	width: 100%;
	height: 100%;
	vertical-align: top;
}
.header-nav {
	display: flex;
	list-style: none;
	padding: 0;
	margin: 0;
}
.header-nav-item {
	margin-left: 1.5rem;
	line-height: 1.2rem;
	opacity: .68;
	transition: .3s;
}
.header-nav-item:hover {
	opacity: 1;
	transition: .3s;
}
.header-nav-item.nav-active {
	opacity: 1;
	position: relative;
}
.header-nav-item.nav-active:after {
	content: '';
	position: absolute;
	top: 1.2rem;
	left: 50%;
	width: .1rem;
	height: .1rem;
	transform: translateX(-50%);
	background-color: #fff;
	border-radius: 50%;
}
.header-nav-link {
	text-decoration: none;
	color: #fff;
	font-size: .6rem;
	vertical-align: top;
}

/* 主图 */
.main-pic-wrapper {
	box-sizing: border-box;
	position: relative;
	width: 100%;
	min-height: 560px;
	background: linear-gradient(to bottom, #36CEF9, #279DF4);
	text-align: center;
	color: #fff;
}
.main-pic-wrapper .main-pic {
	width: 415px;
	vertical-align: top;
}
.main-content {
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	padding: 5%;
	margin-top: .8rem;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.main-title {
	margin: 0;
	font-size: 2rem;
}
.main-desc {
	max-width: 410px;
	margin: 1rem 0;
	font-size: .7rem;
}
.main-mail-to {
	text-decoration: none;
}
.main-button {
	width: 8.6rem;
	height: 2rem;
	font-size: .8rem;
	line-height: 2rem;
	text-align: center;
	color: #fff;
	border: .05rem solid #fff;
	border-radius: 1.5rem;
	cursor: pointer;
	transition: .3s;
}
.main-button:hover {
	background-color: #fff;
	color: rgb(43, 170, 245);
	transition: .3s;
}

/* 服务 */
.service-wrapper {
	box-sizing: border-box;
	background-color: rgb(37, 60, 101);
	color: #fff;
	font-size: 19px;
	overflow: hidden;
}
.service-info {
	text-align: center;
	margin: 2rem 0;
}
.service-list {
	max-width: 1024px;
	margin: 0 auto;
	font-size: 0;
}
.service-item {
	display: inline-block;
	width: 25%;
	font-size: 19px;
	text-align: center;
}
.service-item-img {
	width: 115px;
}
.service-item-type {
	margin: 35px 0 68px 0;
}

/* 产品 */
.product-box {
	height: auto;
}
.product-item {
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	max-width: 820px;
	height: 540px;
	margin: 0 auto;
}
.product-info {
	align-self: center;
	max-width: 10em;
}
.product-info.leftSide {
	order: 0;
	text-align: right;
}
.product-info.rightSide {
	order: 2;
	text-align: left;
}
.product-logo {
	width: 100px;
	height: 100px;
}
.product-name {
	font-size: 1.4rem;
	color: rgb(51, 51, 51);
}
.product-desc {
	font-size: .7rem;
	color: rgb(51, 51, 51);
	margin-bottom: 4px;
}
.product-intro {
	font-size: .7rem;
	color: rgb(119, 119, 119);
	margin-bottom: 4px;
}
.product-tags {
	font-size: 12px;
	color: #fff;
	margin: 4px 0;
}
.product-tags .product-tag {
	display: inline-block;
	padding: 0 10px;
	line-height: 20px;
	border-radius: 12px;
	background-color: rgb(43, 170, 245);
	vertical-align: top;
}
.product-pic-wrapper {
	order: 1;
	flex-shrink: 0;
	max-width: 100%;
}
.product-pic {
	vertical-align: top;
	width: 100%;
}

/* 底部 */
#footer {
	box-sizing: border-box;
	/*padding: 50px 0;*/
	padding: 30px 0;
	background-color: rgb(16, 24, 39);
	color: #fff;
}
.footer-box {
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
	max-width: 70%;
}
.footer-infos {
	font-size: 0;
	width: 500px;
}
.footer-item {
	display: inline-block;
	width: 25%;
	font-size: 14px;
	vertical-align: top;
}
.footer-item-title {
	font-size: 18px;
	margin-bottom: 10px;
}
.footer-item-links {
	line-height: 22px;
}
.footer-item-links .footer-link {
	font-size: 14px;
	opacity: .5;
	cursor: pointer;
}
.footer-wechat-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.footer-wechat-qrcode {
	width: 64px;
	height: 64px;
	margin-bottom: 10px;
}
.wechat-qrcode {
	width: 100%;
	height: 100%;
}
/*.footer-privacy-policy {
	text-align: center;
	margin-bottom: 20px;
}*/
.privacy-policy-link {
	text-decoration: none;
	color: #fff;
	font-size: 14px;
}
.footer-copyright {
	/*margin: 60px 20px 0 20px;*/
	text-align: center;
	font-size: 12px;
}
.footer-author {
	text-align: center;
	font-size: 14px;
}
/* 移动端适配 */
@media screen and (min-width: 1024px) {
	.service-list {
		max-width: 86%;
	}
}

@media screen and (min-width: 1366px) {
	.product-item {
		max-width: 64%;
	}
}


@media screen and (max-width: 1024px) {
	.product-item {
		max-width: 90%;
	}
}

@media screen and (max-width: 980px) {
	.service-list {
		padding: 0;
	}
	.service-item {
		width: 50%;
	}
}

@media screen and (max-width: 910px) {
	.product-item {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: auto;
		padding: 0;
	}
	.product-item .product-info {
		text-align: center;
		order: 0;
		padding: 2rem 1rem;
	}
	.product-item .product-pic-wrapper {
		max-width: 80%;
	}
}

@media screen and (max-width: 768px) {
	#footer {
		padding-left: 0;
		padding-right: 0;
	}
	.footer-infos {
		margin: 0 auto;
		text-align: center;
		width: 100%;
	}
	.footer-box {
		flex-direction: column;
		max-width: 96%;
	}
	.footer-wechat {
		margin-top: 100px;
		text-align: center;
	}
}

@media screen and (max-width: 640px) {
	.service-item {
		width: 100%;
	}
	.footer-box {
		max-width: 100%;
	}
	.footer-item {
		width: 50%;
		margin-top: 50px;
	}
}

@media screen and (max-width: 480px) {
	.main-pic-wrapper {
		min-height: 22rem;
	}
	.main-pic-wrapper .main-pic {
		display: none;
	}
	.footer-item {
		width: 100%;
	}
}

/*@media screen and (max-width: 450px) {
	.main-pic-wrapper .main-pic{
		width: 100%;
	}
}


@media screen and (max-width: 360px) {
	.main-pic-wrapper{
		min-height: 480px;
	}
}


@media screen and (max-width: 320px) {
	.main-pic-wrapper{
		min-height: 360px;
	}
}*/
