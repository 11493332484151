.markdown-box {
	box-sizing: border-box;
	max-width: 768px;
	margin: 60px auto;
	padding: 0 26px;
	font-size: 16px;
	line-height: 26px;
	color: #333;
	font-family: sans-serif;
}

.markdown-box h1 {
   font-size: 35px;
   line-height: 60px;
   color: #333;
}
.markdown-box strong {
	font-size: 20px;
	line-height: 30px;
}